@import './colors.css';

.br_payment_methods_mtr {
    margin-top: 1rem;
}

.br_payment_methods_switch {
    cursor: pointer;
}

.edit_header_icon_save {
    cursor: pointer;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--blue--color);
}

.edit_header_icon_save_disabled {
    cursor: not-allowed;
    margin-right: 15px;
    font-size: 1.5rem;
    color: var(--disabled--color);
}

.br_payment_methods_save_span {
    float: right;
}

.test_success_icon {
    font-size: 1.5rem;
    color: var(--approved--color);
}

.test_error_icon {
    font-size: 1.5rem;
    color: var(--declined--color);
}

.show_hide_key_icon {
    font-size: 1.5rem;
    cursor: pointer;
    color: #6c757d;
}

.select__menu {
    z-index: 99999 !important;
}
